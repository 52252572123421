import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AnalyzingWrapper from './documentPage/AnalyzingWrapper';
import Document from './documentPage/Document';
import { ArchivingDocumentPage, ErrorPage, RemoteData } from '../components';
import { remoteDataShape } from '../lib';

const getRetryAction = props =>
  props.editMode
    ? () => props.fetchEditDocument(props.archivedDocument)
    : props.fetchAnalysis;

const DocumentPage = props => {
  const { documentPage: { documentAnalysis, messageId } } = useSelector(state => state);

  const mapOrAnalyse = () => {
    if (props.isEdit) {
      props.fetchEditDocument(props.archivedDocument);
    } else {
      props.fetchAnalysis();
    }
  };

  useEffect(() => {
    // Because the state is shared, props.documentAnalysis.isNotAsked() is not reliable to check
    // if it needs to be updated. Instead we listen Office.context.mailbox.item.itemId
    if (documentAnalysis.isNotAsked() ||
      (!documentAnalysis.isNotAsked() &&
        messageId &&
        messageId !== props.currentMessageId
      )) {
      mapOrAnalyse();
    }
  }, [props.currentMessageId, props.documentAnalysis]);

  return (
    <AnalyzingWrapper documentAnalysis={props.documentAnalysis} retry={getRetryAction(props)}>
      {
        analysis => (
          <RemoteData
            type={props.hasArchived}
            notAsked={() => (
              <Document
                {...props}
                documentAnalysis={analysis}
              />
            )}
            loading={() => <ArchivingDocumentPage />}
            failure={err => {
              if (err.status === 409) {
                return (
                  <Document {...props} documentAnalysis={analysis} showDocumentExist />
                );
              }
              return (
                <ErrorPage message={err.message} retryAction={props.archiveDocument} />
              );
            }}
            success={_ => <Redirect push to={`/archived/${props.editMode}`} />} />
        )
      }
    </AnalyzingWrapper>
  );
};

DocumentPage.propTypes = {
  applicationRoleName: PropTypes.object,
  archiveDocument: PropTypes.func.isRequired,
  archivedDocument: PropTypes.shape(remoteDataShape).isRequired,
  documentAnalysis: PropTypes.shape(remoteDataShape).isRequired,
  currentMessageId: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  fetchAnalysis: PropTypes.func.isRequired,
  fetchEditDocument: PropTypes.func.isRequired,
  getArchivedDocumentRole: PropTypes.func.isRequired,
  hasArchived: PropTypes.shape(remoteDataShape).isRequired,
  invalidAttachments: PropTypes.arrayOf(PropTypes.string),
  isEdit: PropTypes.bool.isRequired,
  userCanUnArchive: PropTypes.bool.isRequired,
  noteChanged: PropTypes.func.isRequired,
  updateNoteKeywords: PropTypes.func.isRequired,
};

export default DocumentPage;
